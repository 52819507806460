@page {
  margin: 0;
}

.c-discountcard-image {
  position: relative;
  top: 0;
  margin-top: 0;
  img {
    margin: 0;
    //border: 1px solid #E3E5E3;
    box-sizing: border-box;
    filter: drop-shadow(0px 6px 14px rgba(0, 0, 0, 0.09));
    border-radius: 10px;
    overflow: visible;
  }

  &__bg {
    position: absolute;
    left: 6%;
    top: 2rem;
    width: 88%;
    height: 90%;
    background: linear-gradient(90deg, rgba(0, 93, 171, 0.12) -1.09%, rgba(0, 93, 171, 0.04) 100%);
    border-radius: 10px;
  }
}

.c-discountcard-pinch {
  overflow: visible !important;
}

.c-card-home {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 8rem;

  @include iota-breakpoint(md) {
    padding-left: 5%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15.7rem;
    align-items: flex-start;
  }

  >.c-discountcard-image {
    max-height: 31rem;
    max-width: 46.5rem;
  }

  &__info {
    margin-bottom: 4.2rem;

    @include iota-breakpoint(md) {
      padding-top: .8rem;
      max-width: 52%;
      padding-left: 3%;
      margin-bottom: 0;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    color: color(primary_grey);

    >p:last-child {
      color: color(primary_green);
    }
  }

  &__text {
    font-size: 18px;
    line-height: 26px;
    margin: 11px 0 22px;

    @include iota-breakpoint(md) {
      margin: 15px 0 26px;
    }
  }
}

.c-card-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px 0;

  >div:not(:last-child) {
    text-align: center;
  }

  &__card {
    .c-discountcard-image {
      display: block;
      padding: 1rem;
      border: 2px dashed #E3E5E3;
      border-radius: 1rem;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 40px;
    line-height: 52px;
    color: color(primary_grey);
  }

  @media screen {
    &__date {
      display: none;
    }
  }

  &__date {
    font-size: 1rem;
    margin-top: 2rem;
    text-align: center;
  }

  &__text {
    font-size: 24px;
    line-height: 36px;
    margin: 13px 0 41px;
    max-width: 80rem;

    @include iota-breakpoint(md) {
      margin: 14px 0 65px;
    }
  }

  &__card {
    >div:first-child {
      margin-bottom: 30px;

      @include iota-breakpoint(md) {
        margin-bottom: 15px;
      }
    }
  }
}

.c-card-form {
  background: #F5F9FC;
  border-radius: 2rem;
  width: 100%;
  padding: 3.4rem 2.1rem 3.3rem 1.8rem;
  margin-top: 8rem;

  @include iota-breakpoint(md) {
    width: 88.4rem;
    background: color(light_green);
    padding: 3rem 3.2rem 3.7rem 2.6rem;
    margin-top: 7rem;
  }

  @media print {
    display: none;
  }

  .ant-row {
    .ant-form-item {
      margin-bottom: .8rem;

      @include iota-breakpoint(md) {
        margin-bottom: 1.6rem;
      }
    }
  }

  .g-select {
    height: 6.6rem;
  }

  &__title {
    font-size: 28px;
    line-height: 36px;
    font-weight: bold;
    color: color(primary_grey);
    text-align: center;
  }

  &__sub-title {
    font-size: 18px;
    line-height: 26px;
    margin: 1.6rem 0 2.5rem;
    text-align: center;

    @include iota-breakpoint(md) {
      margin: .7rem 0 3.7rem;
    }
  }

  &__submit {
    padding: 13px 42px;
    margin-top: .8rem;

    @include iota-breakpoint(md) {
      margin-top: 0;
    }
  }
}

.c-discountcard {
  position: relative;
  height: 100%;

  &--top {
    margin-top: 1rem;
  }
  &-card {
    transition: 0.2s;
    position: relative;
    top: 0;
    margin-top: 0;

    &:hover {
      opacity: .75;
      cursor: pointer;
    }
  }
}

.c-discountcard:hover .c-discountcard-download {
  display: flex !important;
  position: absolute;
  align-content: center;
  top: 10px;
  right: 10px;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.c-discountcard-download {
  @media (min-width: 768px) {
    display: none;
  }

  @media (max-width: 768px) {
    display: flex !important;
    position: absolute;
    align-content: center;
    top: 10px;
    right: 10px;
    padding: 1rem;
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
  }

  transition: display 0.2s ease-in-out;
  font-size: 2rem;
}

.c-discountcard-dl {
  display: flex !important;
  position: absolute;
  align-content: center;
  top: 10px;
  right: 10px;
  padding: 1rem;
  background-color: #000;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: display 0.2s ease-in-out;
  font-size: 2rem;
}

.hide-element {
  display: none !important;
}
